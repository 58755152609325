import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import resolveUrlForDevice from '../device-url-resolver';

const PlayPage = () => {
  const intl = useIntl();
  useEffect(() => {
    const url = resolveUrlForDevice();
    window.location.replace(url === null ? '/' : url);
    if (url !== null) {
      setTimeout(() => {
        window.location.href = '/';
      });
    }
  });
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'gameName' })} />
    </Layout>
  );
};

export default PlayPage;
